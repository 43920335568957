$color-main: #f1c400;

.process-bar {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e8e8e8;
  font-weight: 700;

  &-title {
    margin-left: 20px;
    font-size: 16px;
  }

  &-info-icon {
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
  }

  &-subtitle {
    font-size: 12px;
  }

  &-sub-step {
    margin-left: auto;
    margin-right: 20px;
    text-align: right;
  }

  &-btn {
    padding: 0 10px;
    min-width: 120px;
    height: 50px;
    font-size: 16px;
    border: none;

    &.btn-prev {
      color: #fff;
      background-color: #c6c6c6;
    }

    &.btn-next {
      color: $color-main;
      background-color: #9e9e9e;
    }
  }

  &-icon-btns {
    position: fixed;
    top: 30px;
    right: 70px;
    width: 100px;
    display: none;
    z-index: 100;
    opacity: 0;
    transform: translateY(-20px);
    transition: all 0.2s;

    &.visible {
      display: flex;
      opacity: 1;
      transform: translateY(0);
    }
  }

  &-icon-btn {
    width: 40px;
    height: 40px;
    margin: auto;
    padding: 0;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.45);
    text-align: center;
    line-height: 40px;
    border-radius: 20px;
    border: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.65);
    }
  }
}
