$color-theme: #f1c400;
$color-white: #fff;
$color-bg: #555;

.contact-us {
  position: relative;
  width: 90%;
  max-width: 900px;
  margin: 40px auto;

  h1 {
    margin-top: 0;
    margin-bottom: 40px;
    color: rgba(0, 0, 0, 0.75);
    font-weight: 700;
  }

  &-info {
    position: absolute;
    top: 70px;
    width: 340px;
    height: 480px;
    padding: 40px;
    background: $color-bg;
    color: $color-white;
    z-index: 1;

    h1 {
      color: $color-white;
    }

    p {
      font-size: 16px;
      padding-left: 30px;
    }

    .contact-links {
      position: absolute;
      right: 20px;
      bottom: 10px;
      width: 160px;
    }
  }

  &-locations {
    margin-left: 200px;
    padding: 60px 60px 60px 200px;

    .contact-country {
      margin: 30px 0;
    }

    .contact-email,
    .contact-phone {
      color: $color-bg;

      &:hover,
      &:active {
        font-weight: 600;
      }

      &:before {
        font-family: FontAwesome, cursive !important;
        margin-left: -30px;
        margin-right: 16px;
        color: $color-theme;
      }
    }

    .contact-email {
      &:before {
        content: '\f0e0';
      }
    }

    .contact-phone {
      &:before {
        content: '\f095';
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .contact-us {
    &-info {
      width: 300px;
    }

    &-locations {
      padding-left: 160px;
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-us {
    width: 100%;
    margin: 0;
    padding: 40px 20px;

    &-info {
      position: relative;
      top: 0;
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }

    &-locations {
      margin: 0;
      padding-left: 60px;
    }
  }
}
