.modal {
  .modal-description {
    padding: 0 24px;
    border-left: 1px dashed #e8e8e8;
    max-height: 80vh;
    overflow-y: auto;

    &-title {
      margin-bottom: 10px;
      font-weight: 700;
    }

    &-text {
      font-size: 12px;
    }
  }
}
