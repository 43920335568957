$box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 8px 20px 0 rgba(0, 0, 0, 0.1);
$color-light-collection: (#16a085, #f39c12, #c0392b);
$color-dark-collection: (#14937a, #cd8109, #a12f23);

.invocation-level-container {
  padding: 40px 20px;

  .invocation-level-col {
    @for $i from 1 through length($color-light-collection) {
      &:nth-child(#{$i}) {
        .invocation-level-card {
          background: nth($color-light-collection, $i);
          border-color: nth($color-dark-collection, $i);
        }

        .invocation-level-title {
          background-color: nth($color-light-collection, $i);
          border-bottom: 10px solid nth($color-dark-collection, $i);
        }
      }
    }
  }

  .invocation-level-card {
    margin-bottom: 20px;
    border-radius: 4px 4px 0 0;
    box-shadow: $box-shadow;
    border-width: 1px 1px 10px 1px;
    border-style: solid;
    transform: perspective(1px) translateZ(0);
    transition: color 0.3s ease-out;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
      transform: scaleY(1);
      transition: transform 0.3s ease-out;
    }

    &:hover {
      color: #fff;

      &:before {
        transform: scaleY(0);
      }
    }

    &.embedded {
      .invocation-level-title {
        padding: 0;
        font-size: 14px;
        line-height: 30px;
      }

      p {
        line-height: 18px;
      }
    }

    .invocation-level-title {
      padding: 20px 0;
      color: #fff;
      font-weight: 700;
      text-align: center;
    }

    .invocation-level-content {
      margin: 10px;
      padding: 10px;
      border-bottom: 1px dashed #888;
      text-align: center;
    }

    .invocation-level-note {
      padding: 10px;
      text-align: center;
    }
  }
}
