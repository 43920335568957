$color-bg: #fff;
$color-border: #d9d9d9;

.collapse {
  border-bottom: 1px solid $color-border;
  background-color: $color-bg;

  .collapse-title {
    padding: 20px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.border {
      border-bottom: 1px solid $color-border;
    }

    .title {
      font-size: 16px;
      font-weight: 700;
    }

    .description {
      margin-left: auto;
      margin-right: 10px;
      font-style: italic;
    }
  }

  .collapse-container {
    overflow: hidden;
    transition: all 0.1s ease-out;

    &.close {
      height: 0;
      display: none;
    }
  }
}
