.organisation-logo-inner {
  padding: 40px;
  min-height: 430px;

  .organisation-logo-title {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }
}

.business-info {
  padding: 20px;

  &-item {
    margin: 10px 0;

    &-title {
      font-weight: 700;
    }
  }
}
