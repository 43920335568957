.card {
  position: relative;
}

.switch-wrapper {
  position: absolute;
  right: 40px;
  top: 28px;
  button {
    margin-left: 16px;
  }
}

.organisation-plan {
  &-header {
    display: flex;
    align-items: center;

    &-avatar {
      margin-right: 16px;
    }

    &-content {
      flex: 1 0;
    }

    &-title {
      font-weight: 600;
    }

    button {
      margin-left: 8px;
    }
  }

  &-list {
    padding-left: 66px;
  }
}
