@import 'fonts.scss';

$color1: #343579;
$color2: #f1c400;
$color3: #241f20;

.certificate {
  width: 100%;
  padding: 32px 0;
  background: #ccc;

  > section {
    position: relative;
    width: 840px;
    height: 1188px;
    margin: 0 auto 32px;
    background-color: #fff;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.25);
  }

  .bg-cover {
    background-image: url(images/background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-contain {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &-inner {
    padding: 100px 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1,
    h2,
    h3,
    figure {
      margin: 0;
    }

    h1 {
      margin-top: 1rem;
      color: $color1;
      font-size: 50px;
      font-family: 'Montserrat';
      font-weight: 700;
    }

    h2 {
      margin: 1rem 0;
      color: $color2;
      line-height: 1.2;
      font-size: 24px;
      font-family: Raleway;
      font-weight: 700;

      &.dotted {
        width: 100%;
        padding-bottom: 20px;
      }
    }

    h3 {
      color: $color1;
      font-size: 18px;
      font-family: 'Montserrat';
    }

    p {
      color: $color3;
    }

    .client-logo {
      width: 150px;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url(images/cert_logo_background.png);
      background-size: cover;
      background-repeat: no-repeat;

      > figure {
        width: 70%;
        height: 70%;
        border-radius: 50%;
        transform: translate(4px, -3px);
      }
    }

    .dotted {
      border-bottom: 2px dotted $color3;
    }

    .date-accept {
      width: 80%;
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;

      > div {
        max-width: 50%;
      }

      .title {
        line-height: 40px;
        font-family: 'Montserrat';
        font-weight: 700;
      }

      .sign {
        margin-top: 10px;
        font-size: 20px;
        font-family: 'Kristi';
      }
    }
  }
}

@media print {
  .certificate {
    background: transparent;
    margin: 0;
    padding: 0;
  }

  .certificate > section {
    margin: 0;
    page-break-after: always;
  }
}

@media screen and (max-width: 768px) {
  .certificate {
    width: 100%;
    padding: 0;
  }

  .certificate > section {
    transform-origin: left top 0;
  }
}
