.info-box-container {
  display: flex;
  font-size: 12px;

  .info-box-icon {
    margin: 10px 30px 10px 10px;
  }

  .info-box-message {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .info-box-btn {
    margin: 5px 0;
    align-self: flex-end;
  }
}

.more-info-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-modal {
    top: 0;
  }

  &-inner {
    width: 90vw;
    max-width: 140vh;
    max-height: 80vh;
    overflow-y: auto;
  }

  .more-info-modal-img {
    height: 80vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
