.plan-preview-back-cover {
  font-size: 12px;
  line-height: 16px;

  .company-intro {
    position: absolute;
    bottom: 550px;
    width: 100%;
    display: flex;
    align-items: center;

    .introduction {
      width: 50%;
      padding-left: 72px;
      margin-right: 40px;
      text-align: right;
    }
  }

  .company-info {
    position: absolute;
    bottom: 300px;
    width: 100%;
    height: 220px;
    background-image: url(../../images/back_cover.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;

    > ul {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > li {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;

        > span {
          width: 50%;
          text-align: right;
          margin-right: 40px;
        }

        > i {
          font-size: 14px;
        }
      }
    }
  }
}
