$color-main: #343579;

.plan-preview-page {
  &.dynamic {
    height: auto;
    min-height: 1188px;

    > main {
      position: relative;
      top: 0;
      width: 100%;
      height: auto;
      padding: 100px 0;
    }
  }

  h1,
  h2,
  h3 {
    margin: 0;
    color: $color-main;
  }

  h1 {
    font-size: 48px;
    font-weight: 400;
    text-transform: uppercase;

    &.bold {
      font-weight: 700;
    }

    &.light {
      font-weight: 300;
    }
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 48px;
    text-transform: uppercase;
  }

  h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;

    &.italic {
      font-style: italic;
    }
  }

  p,
  li {
    line-height: 24px;
  }

  > header {
    position: absolute;
    top: 50px;
    right: 0;
    font-size: 14px;
    text-align: right;

    .client-name {
      padding-right: 60px;
      color: #676767;
      font-weight: 400;
    }

    .plan-document {
      padding-right: 60px;
      color: #f1c400;
      font-weight: 700;
      text-transform: uppercase;
    }

    .header-bar {
      width: 540px;
      height: 10px;
      margin-top: 5px;
      background-image: url(../../images/header_bar.png);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  > main {
    position: absolute;
    top: 160px;
    width: 100%;
    height: 800px;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      .shape {
        width: 120px;
        height: 60px;
        margin-right: 20px;
        padding-right: 20px;
        border-radius: 0 30px 30px 0;
        background-color: $color-main;
        color: #fff;
        line-height: 60px;
        text-align: right;
      }
    }

    .content {
      margin-left: 140px;
      margin-right: 72px;

      .chart {
        margin-bottom: 1em;
        background-repeat: no-repeat;
        background-position: top;
        background-size: contain;

        &.diagram {
          height: 330px;
          background-image: url(../../images/diagram_response.jpg);
        }

        &.rto-rpo {
          height: 460px;
          background-image: url(../../images/rto_rpo.png);
        }
      }

      .chart-description {
        color: $color-main;
        text-align: center;
        font-style: italic;
      }

      .highlight {
        padding: 10px;
        border-width: 4px 0;
        border-style: solid;
        border-color: $color-main;
        text-align: center;

        strong {
          color: #fabc3c;
        }
      }

      p {
        span {
          color: $color-main;
          font-weight: 700;
        }
      }

      ol {
        padding-left: 24px;

        > li {
          padding-left: 16px;
        }
      }

      ul {
        list-style: square;
        padding-left: 24px;
        margin: 20px 0;

        > ul {
          list-style: disc;
          margin: 0;
        }
      }
    }

    .break {
      height: 40px;
      clear: both;
    }
  }

  > footer {
    position: absolute;
    bottom: 40px;
    right: 0;
    display: flex;
    align-items: center;
    font-size: 12px;

    .date {
      text-transform: uppercase;
      margin-right: 5px;
    }

    .page-number {
      width: 55px;
      height: 24px;
      padding-left: 10px;
      background-color: $color-main;
      border-radius: 12px 0 0 12px;
      color: #fff;
      line-height: 24px;
    }
  }
}

@media print {
  .plan-preview-page {
    &.dynamic {
      min-height: auto;
    }
  }
}
