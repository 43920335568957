$color-white: #ffffff;
$color-yellow: #f1c400;

.layout {
  min-height: 100vh;

  .sider {
    background: $color-white;
    border-color: $color-yellow;
    border-style: solid;
    border-width: 0 2px 2px;
    overflow-x: hidden;
    overflow-y: auto;

    .sider-toggle-btn {
      height: 60px;
      font-size: 20px;
      line-height: 60px;
      float: right;
    }

    .sider-top {
      height: 60px;
      padding: 10px 16px;
      display: flex;
      align-items: center;

      &-logo {
        flex-shrink: 0;
      }

      &-title {
        margin-left: 10px;
        flex: 1;
        text-align: center;
        font-weight: 700;
      }
    }

    &.collapsed {
      box-sizing: content-box;

      .sider-toggle-btn {
        width: 100%;
      }

      .sider-top {
        justify-content: center;

        &-title {
          display: none;
        }
      }
    }
  }

  .content {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 1024px) {
  .layout {
    .sider {
      display: none;

      &.show {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .layout {
    .sider {
      position: absolute;
      z-index: 100;
    }
  }
}
