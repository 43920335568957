/* kristi-regular - latin */
@font-face {
  font-family: 'Kristi';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/kristi-v9-latin-regular.eot');
  src: local('Kristi Regular'), local('Kristi-Regular'),
    url('./fonts/kristi-v9-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/kristi-v9-latin-regular.woff2') format('woff2'),
    url('./fonts/kristi-v9-latin-regular.woff') format('woff'),
    url('./fonts/kristi-v9-latin-regular.ttf') format('truetype'),
    url('./fonts/kristi-v9-latin-regular.svg#Kristi') format('svg');
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/montserrat-v12-latin-regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('./fonts/montserrat-v12-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/montserrat-v12-latin-regular.woff2') format('woff2'),
    url('./fonts/montserrat-v12-latin-regular.woff') format('woff'),
    url('./fonts/montserrat-v12-latin-regular.ttf') format('truetype'),
    url('./fonts/montserrat-v12-latin-regular.svg#Montserrat') format('svg');
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/montserrat-v12-latin-700.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('./fonts/montserrat-v12-latin-700.eot?#iefix') format('embedded-opentype'),
    url('./fonts/montserrat-v12-latin-700.woff2') format('woff2'),
    url('./fonts/montserrat-v12-latin-700.woff') format('woff'),
    url('./fonts/montserrat-v12-latin-700.ttf') format('truetype'),
    url('./fonts/montserrat-v12-latin-700.svg#Montserrat') format('svg');
}
