$color-theme: #cb2c30;

.plan-document-container {
  padding: 40px 20px;

  .plan-document-preview {
    position: relative;
    height: 100vh;
    overflow: auto;
  }

  .plan-document-functions {
    min-width: 140px;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .plan-document-btn {
      width: 140px;
      margin: 10px 0;
      background-color: $color-theme;
      border-color: $color-theme;
    }
  }
}
