$color-theme: #a8c08f;
$color-border: #d9d9d9;
$color-bg-grey: #f0f0f0;

.risk-container {
  padding: 40px 20px;

  .ant-switch-checked {
    background-color: $color-theme;
  }

  .risk-info-icon {
    margin-left: 10px;
    color: $color-theme;
    font-size: 14px;
    cursor: pointer;
  }

  .risk-btn {
    background-color: $color-theme;
    border-color: $color-theme;

    &[disabled] {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: $color-border;
    }
  }

  .risk-question {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 1.6;
  }

  .risk-card {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid $color-border;

    &.left {
      align-items: flex-start;
    }

    &-icon {
      margin-bottom: 5px;
      color: $color-theme;
      font-size: 24px !important;
    }

    &-title {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
    }

    &-text {
      font-size: 12px;
    }

    &-btn {
      margin: 0 auto;
      background-color: $color-theme;
      border-color: $color-theme;
    }

    .risk-report-accepted-by {
      padding: 20px;
      background-color: $color-bg-grey;
      color: $color-theme;
      font-size: 12px;
    }
  }

  .risk-form {
    padding: 20px;
    border-bottom: 1px solid $color-border;
  }

  .preview {
    position: relative;
    height: 100vh;
    margin-bottom: 20px;
    overflow: auto;
  }

  .risk-name {
    word-wrap: break-word;
  }
}
