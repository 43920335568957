.slider {
  position: relative;
  height: 12px;
  margin: 14px 0 10px;
  padding: 4px 0;

  &-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: #f5f5f5;
    transition: background-color 0.3s;
    cursor: pointer;
  }

  &-track {
    position: absolute;
    height: 4px;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }

  &-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    margin-left: -7px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 100;
  }

  &:hover {
    .slider-rail {
      background-color: #e1e1e1;
    }
  }
}
