$color-white: #fff;
$color-theme: #f1c400;

%navbar-text {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  white-space: nowrap;
  color: $color-white;
}

.header {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  border: none;

  .header-brand {
    height: 64px;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    border: 2px solid $color-theme;
  }

  .header-hamburger {
    display: none;
    margin-left: 16px;
    color: $color-white;
    font-size: 20px;
  }

  .header-menu {
    margin-left: auto;
    display: flex;
    border: none;
    background-color: $color-theme;

    .header-menu-item {
      top: 0;
      height: 64px;
      width: 86px;
      padding: 0;
      margin: 0;
      border-left: 1px solid $color-white !important;

      > * {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @extend %navbar-text;

        i {
          margin: 0;
          font-size: 16px !important;
          line-height: 24px;
        }
      }
    }

    .ant-menu-submenu {
      display: none;
    }

    .ant-menu-item-selected,
    .ant-menu-item-active {
      background-color: $color-white;

      * {
        color: $color-theme;
      }
    }
  }

  .header-login-btn {
    @extend %navbar-text;
    height: 64px;
    margin-left: auto;
    border: none;
    box-shadow: none;
    background: transparent;
    font-size: 16px;
  }
}

.header-dropdown-menu {
  .header-dropdown-menu-item {
    padding: 0;

    button {
      height: auto;
      width: 100%;
      padding: 10px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 12px;
      font-weight: 700;
      text-align: left;

      &:hover,
      &:active {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    .header-brand {
      display: none;
    }

    .header-hamburger {
      display: block !important;
    }
  }
}
