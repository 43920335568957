.plan-preview-cover {
  .lmi-logo {
    position: absolute;
    right: 60px;
    top: 72px;
    height: 68px;
  }

  .organisation {
    position: absolute;
    top: 297px;
    background-image: url(../../images/cover.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 320px;

    &-logo {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border-radius: 50%;
      width: 210px;
      height: 210px;
      position: absolute;
      right: 90px;
      top: 50px;
    }

    &-name {
      position: absolute;
      left: 72px;
      bottom: 64px;
      font-size: 28px;
      line-height: 1.2;
      white-space: pre-wrap;
    }

    &-date {
      position: absolute;
      left: 72px;
      bottom: 34px;
    }
  }

  .title {
    position: absolute;
    top: 700px;
    left: 72px;

    > h1 {
      margin: 0;
      color: #ffbf00;
      font-size: 68px;
      font-weight: 700;
      line-height: 0.9;
      letter-spacing: 0.5em;
    }

    > h2 {
      margin: 0;
      color: #444b4c;
      font-size: 48px;
      font-weight: 700;
      line-height: 1;

      > strong {
        font-size: 57px;
        letter-spacing: 2px;
      }
    }

    > h3 {
      margin: 0;
      font-size: 30px;
      font-weight: 300;
      letter-spacing: 0.2em;
    }
  }
}
