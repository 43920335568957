.two-factor-banner {
  width: 300px;
  border-radius: 5px;
  background: #f0fcfd;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;
  position: absolute;
  top: 65px;
  right: 0px;
  z-index: 999;

  &:hover {
    background: lighten(#f0fcfd, 10%);
    .arrow-top {
      background: lighten(#f0fcfd, 10%);
    }
  }

  .close-icon-container {
    display: flex;
    justify-content: flex-end;
  }

  .arrow-top {
    width: 16px;
    height: 16px;
    background: #f0fcfd;
    transform: rotate(-43.743deg);
    flex-shrink: 0;
    position: absolute;
    right: 33px;
    top: -5px;
  }

  .heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 15px;
    margin-bottom: 10px;

    h3 {
      font-weight: 700;
    }

    .lock-icon {
      font-size: 60px;
    }
  }

  .contents {
    font-size: 13px;
    color: #000;
    .underline {
      text-decoration: underline;
    }
  }
}
