$color-yellow: #f1c400;
$color-lightblue: #5bc2e7;
$color-black: rgba(0, 0, 0, 0.85);
$color-green: #00685e;
$color-purple: #353679;

@mixin subtitle {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: $color-black;
}

@mixin btn($color) {
  background: $color 0% 0% no-repeat padding-box;
  border-color: $color;
}

.summary {
  padding: 40px 0;

  &-title {
    text-align: center;
    font-weight: 300;
    font-size: 24px;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 0.65);
    opacity: 1;
    margin: 0 0 20px 0;
  }

  &-subtitle {
    @include subtitle;

    &-number {
      font-weight: bold;
    }
  }

  &-chart {
    margin: -40px 0 -60px 0;
  }

  &-operations {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    padding: 0 20vw;

    @media screen and (max-width: 2000px) {
      padding: 0 15vw;
    }

    @media screen and (max-width: 1600px) {
      padding: 0 10vw;
    }

    @media screen and (max-width: 1200px) {
      padding: 0 5vw;
    }

    @media screen and (max-width: 668px) {
      flex-direction: column;
      align-items: center;
    }

    .operation {
      margin: 0 10px;
      width: 20%;
      max-width: 240px;
      min-width: 190px;
      flex: 1 auto;

      @media screen and (max-width: 668px) {
        margin: 10px 0;
      }

      &-btn {
        border-radius: 3px;
        opacity: 1;
        margin-bottom: 8px;

        &[disabled] {
          color: rgba(255, 255, 255, 0.5);
          background: #f5f5f5;
          border-color: #d9d9d9;
        }
      }

      .buy-btn {
        @include btn($color-lightblue);
      }

      .create-btn {
        @include btn($color-green);
      }

      .transfer-btn {
        @include btn($color-purple);
      }

      &-desc {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.4);
        text-align: center;

        @media screen and (max-width: 668px) {
          display: none;
        }
      }
    }
  }

  .summary-breakdown {
    text-align: center;
    margin-top: 40px;

    padding: 0 12vw;

    @media screen and (max-width: 2000px) {
      padding: 0 6vw;
    }

    @media screen and (max-width: 1600px) {
      padding: 0;
    }

    &-title {
      @include subtitle;
    }

    &-container {
      margin-top: 25px;
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      flex-wrap: wrap;

      .summary-breakdown-item-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10%;
        max-width: 220px;
        min-width: 180px;
        min-height: 106px;
        margin: 10px;
        border: 1px solid #d8d8d8;
        opacity: 1;

        .summary-breakdown-item {
          &-name {
            @include subtitle;
            white-space: nowrap;
          }

          &-value {
            font-size: 30px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
