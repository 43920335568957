$color-main: #343579;

.plan-preview-disclaimer {
  .disclaimer {
    height: 100%;
    background-image: url(../../images/background_page.jpg);
    background-repeat: no-repeat;
    background-size: contain;

    .disclaimer-content {
      position: absolute;
      bottom: 0;
      padding: 40px;
      margin: 72px;
      background-color: $color-main;
      color: #fff;

      > h2 {
        margin-bottom: 40px;
        color: #fff;
        font-weight: 700;
      }

      > p {
        line-height: 24px;
      }
    }
  }
}
