$color-theme: #5bc2e7;
$color-button-hover: hsl(196, 74%, 71%);
$color-button-border: rgba(91, 194, 231, 0.502);
$color-bg-grey: #f0f0f0;

.department-container {
  padding: 40px 20px;

  .department-list {
    padding: 20px;

    &-item {
      margin-bottom: 20px;
      padding: 20px;
      background-color: $color-bg-grey;
      text-align: center;
    }
  }

  .department-title {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    line-height: 32px;
  }

  .department-text {
    color: $color-theme;

    &:hover,
    &:active,
    &:focus {
      color: $color-button-hover;
    }
  }

  .department-icon {
    margin-bottom: 5px;
    color: $color-theme;
    font-size: 24px;
  }

  .department-btn {
    background-color: $color-theme;
    border-color: $color-theme;

    &:hover,
    &:active,
    &:focus {
      background-color: $color-button-hover;
      border-color: $color-button-border;
    }
  }
}
