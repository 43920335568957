$color-theme: #f1c400;
$color-border: #d9d9d9;
$color-bg-grey: #f0f0f0;

%single-line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

%select-item {
  position: relative;
  padding: 20px;
  background-color: $color-bg-grey;
  border: 1px solid $color-bg-grey;
  cursor: pointer;
}

%select-item-active {
  border-color: $color-theme;
}

%select-item-active-icon {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $color-theme;
  color: #fff;
  text-align: center;
  line-height: 20px;
  font-family: FontAwesome, cursive !important;
}

.policy-card {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid $color-border;
}

.policy-tile-title {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  @extend %single-line;

  &.margin {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.policy-tile-subtitle {
  font-size: 12px;
  @extend %single-line;

  &.margin {
    margin-top: 10px;
  }
}

.policy-tile-text {
  color: $color-theme;
  font-size: 12px;
  @extend %single-line;
}

.policy-tile-paragraph {
  font-size: 12px;

  span {
    color: $color-theme;
  }
}

.policy-icon {
  margin-bottom: 5px;
  color: $color-theme;
  font-size: 24px !important;
}

.policy-members {
  padding: 20px;

  &-item {
    margin-bottom: 20px;
    text-align: center;
    @extend %select-item;

    &.active {
      @extend %select-item-active;

      &:before {
        content: '\f00c';
        @extend %select-item-active-icon;
      }
    }
  }
}

.policy-responsibles {
  padding: 20px;

  &-item {
    min-height: 100px;
    margin-bottom: 20px;
    @extend %select-item;

    &.active {
      @extend %select-item-active;

      &:before {
        content: '\f00c';
        @extend %select-item-active-icon;
      }
    }
  }
}

.policy-accepted-by {
  max-width: 100%;
  @extend %select-item;

  .policy-accepted-names {
    color: $color-theme;
    font-size: 12px;
  }
}

.policy-document-container {
  padding: 40px 20px;

  .policy-document-preview {
    position: relative;
    height: 100vh;
    overflow: auto;
  }

  .policy-document-functions {
    min-width: 140px;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .policy-btn {
      width: 140px;
      margin: 10px 0;
    }
  }
}
