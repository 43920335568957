$color-theme: #5bc2e7;
$color-button-hover: hsl(196, 74%, 71%);
$color-button-border: rgba(91, 194, 231, 0.502);

.team-btn {
  background-color: $color-theme;
  border-color: $color-theme;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-button-hover;
    border-color: $color-button-border;
  }
}

.team-link {
  color: $color-theme;

  &:hover,
  &:active,
  &:focus {
    color: $color-button-hover;
  }
}
