$color-main: #343579;

.plan-preview-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;
  margin-bottom: 20px;

  .table-header {
    border-top: 2px solid $color-main;
  }

  td,
  th {
    padding: 7px 5px;
  }

  .table-top-row {
    color: $color-main;
    text-align: left;
  }

  .table-top-action-row {
    background-color: $color-main;
    color: #fff;
  }

  .table-content {
    height: 37px;
    background-color: #efefef;
    border-width: 2px 2px 0 2px;
    border-style: solid;
    border-color: #fff;

    > ul {
      margin: 0;
      list-style: disc;
    }

    span {
      color: $color-main;

      &.italic {
        font-style: italic;
      }
    }

    br {
      line-height: 2.5;
    }
  }

  .checkpoint {
    background-color: #fff;
    color: $color-main;
    border-width: 2px 0;
    border-style: solid;
    border-color: $color-main;
  }
}
