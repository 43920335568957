.doc-container {
  padding: 40px 20px;

  &-inner {
    padding: 20px;
  }

  .doc-header {
    margin-bottom: 20px;
  }

  .folder-list {
    margin-bottom: 20px;

    .folder-tile {
      min-height: 150px;
      margin-bottom: 20px;
      padding: 1.8rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background: #f4f4f4;
      text-align: center;
      transition: box-shadow linear 150ms;
      cursor: pointer;

      &:hover,
      &:focus,
      &.selected {
        box-shadow: 0 0 1px 1px #f1c400;
      }

      > i {
        display: inline-block;
        margin-bottom: 0.2rem;
      }

      &-title {
        margin-bottom: 0;
        color: #555;
        font-size: 12px;
        text-transform: uppercase;
      }

      &-count {
        margin-bottom: 0;
        color: #aaa;
        font-size: 12px;
        font-weight: 300;
      }

      &-edit {
        margin-top: 0.5rem;
      }

      &-icon {
        margin-bottom: 0;
        font-size: 30px;
      }
    }
  }

  .document-list {
    padding: 20px 20px 40px 20px;
    margin-bottom: 60px;
    border: 1px solid #d9d9d9;

    .document-icons {
      display: flex;
      justify-content: space-between;

      i {
        font-size: 16px;
      }
    }

    a {
      color: #555;
      text-decoration: underline;

      &:hover {
        color: #f1c400;
      }
    }
  }
}

.doc-icon-book {
  width: 30px;
  height: 30px;
  background-position: -0 -0;
}

.doc-icon-config {
  width: 30px;
  height: 30px;
  background-position: -30px -0;
}

.doc-icon-exit {
  width: 30px;
  height: 30px;
  background-position: -0 -30px;
}

.doc-icon-floor {
  width: 30px;
  height: 30px;
  background-position: -30px -30px;
}

.doc-icon-network {
  width: 30px;
  height: 30px;
  background-position: -60px -0;
}

.doc-icon-settings {
  width: 30px;
  height: 30px;
  background-position: -60px -30px;
}

.doc-icon-sign {
  width: 30px;
  height: 30px;
  background-position: -0 -60px;
}

.doc-icon-umbrella {
  width: 30px;
  height: 30px;
  background-position: -30px -60px;
}

.doc-icon-users {
  width: 30px;
  height: 30px;
  background-position: -60px -60px;
}
