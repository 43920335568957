.purchase {
  max-width: 800px;
  margin: 40px auto;

  .purchase-step {
    margin-bottom: 40px;
  }

  .purchase-form {
    width: 370px;
    margin: 0 auto;
  }

  .purchase-action-btn {
    width: 100%;
    margin: 5px 0px;
  }
}
