$color-main: #343579;
$color-bg-grey: #f0f0f0;

.risk-report-preview {
  width: fit-content;
  min-width: 100%;
  padding: 32px 16px;
  background: #ccc;

  > section {
    position: relative;
    width: 840px;
    height: 1188px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 2px 4px 5px 0 rgba(0, 0, 0, 0.25);
  }

  &-cover {
    position: relative;
    height: 100%;

    .lmi-logo {
      position: absolute;
      right: 60px;
      top: 72px;
      height: 68px;
    }

    .organisation {
      position: absolute;
      top: 297px;
      background-image: url(images/cover.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 320px;

      &-logo {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border-radius: 50%;
        width: 210px;
        height: 210px;
        position: absolute;
        right: 90px;
        top: 50px;
      }

      &-name {
        position: absolute;
        left: 72px;
        bottom: 64px;
        font-size: 28px;
        line-height: 1.2;
        white-space: pre-wrap;
      }

      &-date {
        position: absolute;
        left: 72px;
        bottom: 34px;
      }
    }

    .title {
      position: absolute;
      top: 700px;
      left: 72px;

      > h1 {
        margin: 0;
        color: #ffbf00;
        font-size: 72px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: 40px;
      }

      > h2 {
        margin: 0;
        color: #444b4c;
        font-size: 60px;
        line-height: 1;
        letter-spacing: 12px;

        > strong {
          font-weight: 700;
          letter-spacing: 1px;
        }
      }

      > h3 {
        margin: 0;
        font-size: 30px;
        font-weight: 300;
        letter-spacing: 0.2em;
      }
    }
  }

  &-disclaimer {
    position: relative;
    height: 100%;

    .disclaimer {
      height: 100%;
      background-image: url(images/background_page.jpg);
      background-repeat: no-repeat;
      background-size: contain;

      .disclaimer-content {
        position: absolute;
        bottom: 0;
        padding: 40px;
        margin: 72px;
        background-color: $color-main;
        color: #fff;

        > h2 {
          margin-bottom: 40px;
          color: #fff;
          font-weight: 700;
        }

        > p {
          line-height: 24px;
        }
      }
    }
  }

  &-main {
    position: relative;
    width: 840px;
    margin: 0 auto;
    padding: 100px 0;
    background: #fff;
    box-shadow: 2px 4px 5px 0 rgba(0, 0, 0, 0.25);

    h1,
    h2,
    h3 {
      margin: 0;
      color: $color-main;
    }

    h1 {
      font-size: 48px;
      font-weight: 400;
      text-transform: uppercase;

      &.bold {
        font-weight: 700;
      }

      &.light {
        font-weight: 300;
      }
    }

    h2 {
      font-size: 18px;
      font-weight: 700;
      line-height: 48px;
      text-transform: uppercase;
    }

    h3 {
      font-size: 16px;
      font-weight: 700;
      line-height: 30px;

      &.italic {
        font-style: italic;
      }
    }

    p,
    li {
      line-height: 24px;
    }

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      clear: both;

      .shape {
        width: 120px;
        height: 60px;
        margin-right: 20px;
        padding-right: 20px;
        border-radius: 0 30px 30px 0;
        background-color: $color-main;
        color: #fff;
        line-height: 60px;
        text-align: right;
      }
    }

    .content {
      margin-left: 140px;
      margin-right: 72px;
      padding-bottom: 80px;

      .subtitle {
        font-size: 18px;
      }

      p {
        span {
          color: $color-main;
          font-weight: 700;
        }
      }

      figure {
        img {
          width: 100%;
        }

        figcaption,
        .chart-description {
          color: $color-main;
          text-align: center;
          font-style: italic;
        }
      }

      ol {
        padding-left: 24px;

        > li {
          padding-left: 16px;
        }
      }

      ul {
        list-style: square;
        padding-left: 24px;
        margin: 20px 0;
      }

      .formula {
        width: 50%;
        background-size: contain;
        background-repeat: no-repeat;

        &.formula1 {
          height: 39px;
          background-image: url(images/formula1.jpg);
        }

        &.formula2 {
          height: 39px;
          background-image: url(images/formula2.jpg);
        }

        &.formula3 {
          height: 156px;
          background-image: url(images/formula3.jpg);
        }

        &.formula4 {
          height: 156px;
          background-image: url(images/formula4.jpg);
        }
      }
    }

    .table-of-content {
      padding-bottom: 80px;
      display: flex;
      justify-content: flex-end;

      > ul {
        width: 500px;
        margin-right: 72px;

        > li {
          margin: 10px 0;
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          text-transform: uppercase;

          > div {
            padding: 10px;
            background-color: $color-bg-grey;
          }

          .section {
            width: 60px;
          }

          .item {
            width: 360px;
          }

          .page {
            width: 60px;
            text-align: center;
          }
        }
      }
    }

    .break {
      height: 80px;
      clear: both;
    }
  }

  &-table {
    margin-bottom: 40px;

    > table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      font-size: 14px;

      .table-header {
        border-top: 1px solid $color-main;
        text-transform: uppercase;
      }

      td,
      th {
        padding: 7px 5px;
      }

      .table-top-row {
        color: $color-main;
        text-align: left;
      }

      .table-top-action-row {
        background-color: $color-main;
        color: #fff;
      }

      .table-content {
        height: 37px;
        background-color: #efefef;
        border-width: 2px 2px 0 2px;
        border-style: solid;
        border-color: #fff;

        ul {
          margin: 0;
          list-style: disc;
        }

        span {
          color: $color-main;

          &.italic {
            font-style: italic;
          }
        }

        br {
          line-height: 2.5;
        }
      }
    }
  }

  &-back-cover {
    position: relative;
    height: 100%;
    font-size: 12px;
    line-height: 16px;

    .company-intro {
      position: absolute;
      bottom: 550px;
      width: 100%;
      display: flex;
      align-items: center;

      .introduction {
        width: 50%;
        padding-left: 72px;
        margin-right: 40px;
        text-align: right;
      }

      .logo {
        width: 200px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .company-info {
      position: absolute;
      bottom: 300px;
      width: 100%;
      height: 220px;
      background-image: url(images/back_cover.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      color: #fff;

      > ul {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > li {
          width: 100%;
          height: 36px;
          display: flex;
          align-items: center;

          > span {
            width: 50%;
            text-align: right;
            margin-right: 40px;
          }

          > i {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  .risk-report-preview {
    margin: 0;
    padding: 0;
    background: transparent;

    > section {
      width: 21cm;
      height: 29.7cm;
      margin: 0;
      box-shadow: none;
      page-break-after: always;
    }

    &-main {
      width: 21cm;
      margin: 0;
      padding: 0;
      box-shadow: none;
      page-break-after: always;

      .title {
        page-break-before: always;
      }

      .content {
        padding: 0;
      }
    }

    p,
    li,
    thead,
    .title,
    .widget {
      page-break-inside: avoid;
    }

    h1,
    h2,
    h3 {
      page-break-after: avoid;
    }
  }
}

@media screen and (max-width: 768px) {
  .risk-report-preview {
    width: 100%;
    padding: 0;
  }

  .risk-report-preview > section {
    transform-origin: left top 0;
  }
}
