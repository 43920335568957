$color-main: #343579;
$color-bg-grey: #f0f0f0;

.policy-preview {
  width: 100%;
  padding: 32px 0;
  background: #ccc;

  > section {
    position: relative;
    width: 840px;
    height: 1188px;
    margin: 0 auto 32px;
    background: #fff;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.25);
  }

  &-cover {
    .lmi-logo {
      position: absolute;
      right: 60px;
      top: 72px;
      height: 68px;
    }

    .organisation {
      position: absolute;
      top: 297px;
      background-image: url(images/cover.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 320px;

      &-logo {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border-radius: 50%;
        width: 210px;
        height: 210px;
        position: absolute;
        right: 90px;
        top: 50px;
      }

      &-name {
        position: absolute;
        left: 72px;
        bottom: 64px;
        font-size: 28px;
        line-height: 1.2;
        white-space: pre-wrap;
      }

      &-date {
        position: absolute;
        left: 72px;
        bottom: 34px;
      }
    }

    .title {
      position: absolute;
      top: 700px;
      left: 72px;

      > h1 {
        color: #ffbf00;
        font-size: 50px;
        font-weight: 700;
        line-height: 1;
        margin: 0;

        > strong {
          font-size: 88px;
        }
      }

      > h2 {
        color: #444b4c;
        font-size: 48px;
        font-weight: 700;
        margin: 0;
      }
    }
  }

  &-disclaimer {
    .disclaimer {
      height: 100%;
      background-image: url(images/background_page.jpg);
      background-repeat: no-repeat;
      background-size: contain;

      .disclaimer-content {
        position: absolute;
        bottom: 0;
        padding: 40px;
        margin: 72px;
        background-color: $color-main;
        color: #fff;

        > h2 {
          margin-bottom: 40px;
          color: #fff;
          font-weight: 700;
        }

        > p {
          line-height: 24px;
        }
      }
    }
  }

  &-page {
    h1,
    h2,
    h3 {
      margin: 0;
      color: $color-main;
    }

    h1 {
      font-size: 48px;
      font-weight: 400;
      text-transform: uppercase;

      &.bold {
        font-weight: 700;
      }

      &.light {
        font-weight: 300;
      }
    }

    h2 {
      font-size: 18px;
      font-weight: 700;
      line-height: 48px;
      text-transform: uppercase;
    }

    h3 {
      font-size: 14px;
      font-weight: 700;
      line-height: 30px;

      &.italic {
        font-style: italic;
      }
    }

    p,
    li {
      line-height: 24px;
    }

    > header {
      position: absolute;
      top: 72px;
      right: 0;
      font-size: 14px;
      text-align: right;

      .client-name {
        padding-right: 60px;
        color: #676767;
        font-weight: 400;
      }

      .policy-document {
        padding-right: 60px;
        color: #f1c400;
        font-weight: 700;
        text-transform: uppercase;
      }

      .header-bar {
        width: 540px;
        height: 10px;
        margin-top: 5px;
        background-image: url(images/header_bar.png);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    > main {
      position: absolute;
      top: 200px;
      width: 100%;
      height: 800px;

      .title {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        .shape {
          width: 120px;
          height: 60px;
          margin-right: 20px;
          padding-right: 20px;
          border-radius: 0 30px 30px 0;
          background-color: $color-main;
          color: #fff;
          line-height: 60px;
          text-align: right;
        }
      }

      .content {
        width: 450px;
        margin-right: 72px;
        float: right;

        ol {
          padding-left: 24px;

          > li {
            padding-left: 16px;
          }
        }

        ul {
          list-style: square;
          padding-left: 24px;
          margin: 20px 0;
        }
      }

      .break {
        height: 80px;
        clear: both;
      }
    }

    > footer {
      position: absolute;
      bottom: 40px;
      right: 0;
      display: flex;
      align-items: center;
      font-size: 12px;

      .date {
        text-transform: uppercase;
        margin-right: 5px;
      }

      .page-number {
        width: 55px;
        height: 24px;
        padding-left: 10px;
        background-color: $color-main;
        border-radius: 12px 0 0 12px;
        color: #fff;
        line-height: 24px;
      }
    }
  }

  &-table-of-content {
    > ul {
      width: 500px;
      margin-right: 72px;
      float: right;

      > li {
        margin: 40px 0;
        display: flex;
        justify-content: space-between;
        font-size: 20px;

        .item {
          width: 400px;
          padding: 10px 30px;
          background-color: $color-bg-grey;
        }

        .page {
          width: 90px;
          padding: 10px;
          background-color: $color-bg-grey;
          text-align: center;
        }
      }
    }
  }

  &-statement {
    .policy-statement-title {
      width: 600px;
      height: 200px;
      margin-top: 200px;
      margin-bottom: 60px;
      padding-left: 72px;
      border-radius: 0 100px 100px 0;
      background-color: $color-bg-grey;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &-back-cover {
    font-size: 12px;
    line-height: 16px;

    .company-intro {
      position: absolute;
      bottom: 550px;
      width: 100%;
      display: flex;
      align-items: center;

      .introduction {
        width: 50%;
        padding-left: 72px;
        margin-right: 40px;
        text-align: right;
      }
    }

    .company-info {
      position: absolute;
      bottom: 300px;
      width: 100%;
      height: 220px;
      background-image: url(images/back_cover.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      color: #fff;

      > ul {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > li {
          width: 100%;
          height: 36px;
          display: flex;
          align-items: center;

          > span {
            width: 50%;
            text-align: right;
            margin-right: 40px;
          }

          > i {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media print {
  .policy-preview {
    background: transparent;
    margin: 0;
    padding: 0;

    > section {
      margin: 0;
      page-break-after: always;
      width: 21cm;
      height: 29.7cm;
    }
  }
}

@media screen and (min-width: 1024px) {
  .policy-preview {
    > section {
      transform: scale(1);
    }
  }
}

@media screen and (max-width: 839px) {
  .policy-preview {
    width: 100%;
    padding-left: 60px;

    > section {
      transform-origin: left top 0;
      transform: scale(0.85);

      + section {
        margin-top: -180px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .policy-preview {
    width: 768px;
    padding-left: 69px;

    > section {
      transform-origin: left top 0;
      transform: scale(0.75);

      + section {
        margin-top: -300px;
      }
    }
  }
}
