$color-bg-grey: #f0f0f0;

.plan-preview {
  width: fit-content;
  min-width: 100%;
  padding: 32px 16px;
  background: #ccc;

  > section {
    position: relative;
    width: 840px;
    height: 1188px;
    margin: 0 auto 32px;
    background: #fff;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.25);
  }

  &-table-of-content {
    > ul {
      width: 620px;
      margin-right: 72px;
      float: right;

      > li {
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        text-transform: uppercase;

        > div {
          padding: 10px;
          background-color: $color-bg-grey;
        }

        .section {
          width: 110px;
        }

        .item {
          width: 440px;
        }

        .page {
          width: 50px;
          text-align: center;
        }
      }
    }
  }
}

@media print {
  .plan-preview {
    margin: 0;
    padding: 0;
    background: transparent;

    > section {
      width: 21cm;
      height: 29.7cm;
      margin: 0;
      box-shadow: none;
      page-break-after: always;
    }
  }
}
