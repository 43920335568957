$color-main: #343579;
$color-bg-grey: #f0f0f0;

.plan-preview-navigation {
  display: flex;

  .instruction {
    position: relative;
    width: 35%;
    background-color: $color-bg-grey;
    color: $color-main;
    text-align: center;
    text-transform: uppercase;

    .title {
      position: absolute;
      top: 200px;
      padding: 40px;
      font-size: 32px;
      font-weight: 700;
    }

    .description {
      position: absolute;
      top: 400px;
      padding: 60px;
      font-size: 24px;
      line-height: 2;
    }
  }

  .teams {
    width: 65%;
    margin-top: 120px;
    padding-left: 30px;
    padding-right: 40px;
    text-transform: uppercase;

    .team-type {
      margin: 50px 0;

      .team-icon {
        padding-bottom: 5px;
        border-bottom: 3px solid $color-main;
        color: $color-main;
        font-size: 30px;
      }

      .team-title {
        color: $color-main;
        font-size: 20px;
        font-weight: 700;
        line-height: 60px;
      }

      .team-sections {
        .team-section-item {
          display: block;
          margin: 4px 0;
          padding: 10px 5px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
