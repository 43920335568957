$color-theme: #343579;
$color-border: #d9d9d9;
$color-collection: (#241023, #dd7049, #f9c761, #fffa84, #c9e094);

.bia-chart-container {
  display: flex;
  flex-wrap: wrap;
}

.drs-category {
  width: calc(100% - 40px);
  height: 20%;
  margin: 0 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid $color-border;

  &-item {
    width: 16.6667%;
    height: 100%;
    padding: 20px 10px;
    color: $color-theme;
    font-size: 11px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &.active,
    &:hover {
      background-color: $color-theme;
      color: #fff;
    }
  }

  &-icon {
    margin-bottom: 10px;
    font-size: 20px !important;
  }
}

.scale-list {
  width: 20%;
  padding-top: 20px;

  .scale-item {
    display: flex;
    align-items: center;
    line-height: 36px;

    &-circle {
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    @for $i from 1 through length($color-collection) {
      &:nth-child(#{$i}) {
        .scale-item-circle {
          background-color: nth($color-collection, $i);
        }
      }
    }

    &-name {
      margin-left: 10px;
      color: $color-theme;
    }
  }
}

.bia-detail-back {
  margin-bottom: 10px;
}

.bia-detail-container {
  height: 500px;
  overflow: auto;

  .critical-periods-list {
    height: 50%;
    padding: 10px 0;
    border-top: 1px solid $color-border;
    overflow: auto;
  }

  .bia-detail-item {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-border;
  }
}
