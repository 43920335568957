$color1: #5bc2e7;
$color2: #343579;
$color3: #f1c400;
$color4: #cb2c30;
$color5: #00685e;
$color-bg: #fff;

%menu-item-title {
  border-left: 4px solid;
}

.sider {
  &-plan {
    padding: 0 20px 5px;
    text-align: center;
  }

  .menu-switch {
    margin-bottom: 16px;

    > .ant-menu-item {
      width: 50%;
      text-align: center;
    }
  }
}

.sider-menu {
  padding-bottom: 45px;

  .ant-menu-item {
    margin: 4px 0 !important;
  }

  .ant-menu-submenu {
    .ant-menu-item {
      padding-left: 20px !important;
      height: 28px;
      line-height: 28px;
      font-size: 13px;
    }

    .ant-menu-submenu {
      padding-left: 10px;
    }
  }

  .sider-menu-program {
    > .ant-menu-submenu-title {
      @extend %menu-item-title;
      border-color: $color1;
    }
  }

  .sider-menu-analysis {
    > .ant-menu-submenu-title {
      @extend %menu-item-title;
      border-color: $color2;
    }
  }

  .sider-menu-design {
    > .ant-menu-submenu-title {
      @extend %menu-item-title;
      border-color: $color3;
    }
  }

  .sider-menu-implementation {
    > .ant-menu-submenu-title {
      @extend %menu-item-title;
      border-color: $color4;
    }
  }

  .sider-menu-validation {
    > .ant-menu-submenu-title {
      @extend %menu-item-title;
      border-color: $color5;
    }
  }
}

.sider-menu,
.menu-switch {
  border-right: none;

  &.ant-menu-inline-collapsed {
    margin: 0;

    > .ant-menu-item,
    .ant-menu-submenu-title {
      padding: 0 30px !important;

      .anticon {
        font-size: 20px;
      }
    }
  }
}
