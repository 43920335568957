$color-white: #fff;

.news-card {
  background: $color-white;
  padding: 5px;
  margin: 0 5px;
  position: relative;

  .ant-card-cover {
    .ant-spin-nested-loading {
      .ant-spin-container {
        .news-img-container {
          position: relative;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 160px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
  .ant-card-body {
    padding: 10px 20px 15px;
  }

  .ant-card-meta {
    margin: 0 0 10px;
    width: 100%;
    text-align: left;

    &-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: normal;
    }

    &-detail {
      height: 90px;
    }

    &-description {
      > div {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        margin: 0 auto;
        font-size: 14px;
        line-height: 1;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .ant-divider-horizontal {
    margin: 0;
  }

  .news-detailed {
    width: 85%;
    font-weight: 500;
    text-align: left;
    margin-top: 10px;

    &-author {
      font-size: 0.7rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-date {
      font-size: 0.875rem;

      i {
        color: #f1c400;
        padding-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .news-card {
    margin: 0 5px;
    height: 240px;

    .ant-card-body {
      padding: 0.5rem 1rem;
    }

    .ant-card-cover {
      .ant-spin-nested-loading {
        .ant-spin-container {
          .news-img-container {
            height: 120px;
          }
        }
      }
    }

    .ant-card-meta {
      margin: 0;

      &-title {
        font-size: 0.875rem;
      }

      &-detail {
        height: auto !important;
      }

      &-description {
        > div {
          height: 2rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
