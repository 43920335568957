.counter {
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 50px;
    text-align: center;
  }

  button {
    height: 32px;
  }
}
