@import '~normalize.css/normalize.css';
@import '~font-awesome/css/font-awesome.min.css';

body {
  margin: 0;
  padding: 0;
}

* {
  word-wrap: normal;
}

#root {
  height: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.loading {
  width: 100%;
  margin: 40px auto;
}

.component-wrapper {
  padding: 40px 20px;
}

.responsive-video-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;

  .responsive-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

.table,
.list,
.tabs {
  padding: 20px;
}

.btn-container {
  padding: 20px;
  display: flex;
  justify-content: flex-end;

  button:not(:first-child) {
    margin-left: 20px;
  }

  &.no-padding {
    padding: 0;
    margin-top: 20px;
  }

  &.inline {
    padding: 0;

    button:not(:first-child) {
      margin-left: 10px;
    }
  }

  &.center {
    justify-content: center;

    button {
      margin: 0 10px;
    }
  }

  &.space-between {
    justify-content: space-between;

    button {
      margin: 0;
    }
  }
}

.flex {
  display: flex;

  &.start {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.end {
    justify-content: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }
}

.single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-important {
  background: rgb(203, 44, 48) !important;
  border-color: rgb(203, 44, 48) !important;
}

// override antd styles
.ant-table {
  .ant-table-thead {
    th {
      background: #fff;
    }
  }
}

.ant-radio-group {
  text-transform: capitalize;

  .ant-radio-inner::after {
    border-radius: 50%;
  }
}

.ant-avatar {
  background-color: #ddd;

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    object-fit: contain;
  }
}

@media print {
  div[class^='_hj'] {
    display: none;
  }
}
