$box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 8px 20px 0 rgba(0, 0, 0, 0.1);
$color-bg: #fff;
$color-border: #d9d9d9;

.card {
  margin-bottom: 20px;
  background-color: $color-bg;
  box-shadow: $box-shadow;
  border-top: 4px solid;
  border-radius: 4px 4px 0 0;

  .card-title {
    padding: 20px 20px 10px 20px;
    border-bottom: 1px solid $color-border;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title,
    .subtitle {
      font-size: 16px;
      font-weight: 700;
    }

    .title {
      line-height: 2.6;
    }

    .description {
      color: #999;
      font-size: 12px;
    }

    .more {
      font-weight: 700;
    }
  }
}
