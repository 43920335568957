.shopping-cart {
  .discount-code {
    margin: 40px 0 20px 0;

    .discount-alert {
      margin-top: 10px;
    }
  }

  .fee {
    display: flex;
    justify-content: space-between;
    line-height: 36px;

    &.discount {
      color: rgb(203, 44, 48);
    }
  }

  .btn-pay {
    width: 100%;
    margin-top: 20px;
  }
}
