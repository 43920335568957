.glossary-container {
  padding: 40px 20px;

  .glossary-inner {
    padding: 40px;

    h2 {
      width: 66px;
      color: #fff;
      background: #f1c400;
      padding-left: 20px;
      border-radius: 0 16px 16px 0;
    }

    .glossary-search-input {
      margin-bottom: 40px;
    }

    .glossary-table {
      margin-bottom: 40px;
    }

    .no-result-text {
      color: #999;
      text-align: center;
    }
  }
}
