$color-banner-title: #343579;
$color-theme: #f1c400;
$color-theme-dark: #2d2929;
$color-bg-grey: #f7f7f7;
$color-border: #d9d9d9;
$color-font-grey: #9b9b9b;
$color-btn-main: #00685e;
$color-white: #fff;
$color-collection: (#5bc2e7, #343579, #f1c400, #cb2c30, #00685e);
$icons-url: 'images/icons.png';
$icon-height: 55px;
$icon-width: 55px;

%btn-main {
  padding: 0 40px;
  background-color: $color-btn-main;
  border: none;
  color: $color-white;
  font-weight: 700;
}

@mixin icon($index) {
  width: $icon-width;
  height: $icon-height;
  margin: auto;
  background: url($icons-url) no-repeat;
  background-position: -($icon-width * $index) 0;
}

.landing-container {
  background-color: $color-white;

  h1 {
    margin: 15px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
  }

  h2 {
    margin: 10px 0;
    font-size: 36px;
  }

  h3 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }
}

.landing-banner {
  height: 850px;
  background-size: 40%;
  background-position: center right;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;

  .steps {
    position: absolute;
    right: 18%;
    bottom: 10%;
    width: 60%;
    z-index: 1;
  }

  .ring {
    position: absolute;
    width: 38%;
    right: 1%;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 0;
  }
}

.landing-banner-text {
  width: 45%;
  margin: 8vw 0 0 14vw;
  font-family: Raleway, sans-serif;
  z-index: 2;
  position: relative;

  h1 {
    color: $color-banner-title;
    font-size: 60px;
  }

  h2 {
    font-size: 52px;
    line-height: 1;
  }

  p.slogan {
    font-size: 24px;
    max-width: 600px;
  }
}

.landing-section-two {
  margin: 0 !important;
  padding: 140px 0 100px;
  background-color: $color-theme;
  text-align: right;
}

.landing-section-three {
  padding: 40px 0;
  text-align: center;
  background-color: $color-bg-grey;

  h1 {
    color: $color-theme;
  }

  ul {
    display: flex;

    li {
      width: 20%;
      padding: 40px 20px;

      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          .icon {
            @include icon($i - 1);
          }
        }
      }

      h3 {
        margin: 20px 0;
        font-weight: 700;
      }
    }
  }
}

.landing-section-four {
  padding-top: 40px;

  &-list {
    display: flex;
    align-items: center;
  }

  &-image {
    overflow: hidden;
    height: 670px;

    .landing-banner {
      background-size: 90%;
    }
  }

  ul {
    flex: 1;

    li {
      padding: 20px;
      margin: 20px 0;
      background-color: $color-theme-dark;
      border-left: 5px solid $color-white;
      color: $color-white;
      font-size: 16px;
      font-weight: 700;

      @for $i from 1 through length($color-collection) {
        &:nth-child(#{$i}) {
          box-shadow: -7px 0 nth($color-collection, $i);
        }
      }
    }
  }
}

.landing-section-five {
  &-overlay {
    padding: 50px 0;
    background-color: rgba(52, 53, 121, 0.9);
    text-align: center;
  }

  h1,
  h2,
  h3 {
    color: $color-white;
    font-weight: 700;
  }
}

.landing-section-six {
  padding: 40px 0;
  text-align: center;

  h1,
  h2 {
    margin: 0;
  }

  p {
    font-size: 12px;
  }


  .landing-disruption {
    display: flex !important;

    &-btn {
      width: 5%;
      height: auto;
      line-height: 0;
      font-size: 60px;
      align-self: center;
      cursor: pointer;
    }

    &-carousel {
      width: 90%;
      margin: 40px 0;

      .landing-disruption-item {
        width: 170px !important;
        height: 170px;
        padding: 10px 20px;
        border-radius: 50%;
        border-width: 10px;
        border-style: solid;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.blue {
          border-color: nth($color-collection, 1);
          color: nth($color-collection, 1);
        }

        &.dark-blue {
          border-color: nth($color-collection, 2);
          color: nth($color-collection, 2);
        }

        &.yellow {
          border-color: nth($color-collection, 3);
          color: nth($color-collection, 3);
        }

        &.red {
          border-color: nth($color-collection, 4);
          color: nth($color-collection, 4);
        }

        &.green {
          border-color: nth($color-collection, 5);
          color: nth($color-collection, 5);
        }

        &-icon {
          font-size: 36px;
        }

        &-rank {
          margin: 5px 0;
          color: $color-font-grey;
          font-size: 34px;
          font-weight: 700;
          line-height: 36px;

          sup {
            vertical-align: middle;
          }
        }

        &-title {
          height: 50px;
          font-size: 12px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
  }
}

.landing-section-seven {
  padding: 40px 0;
  text-align: center;
  background-color: $color-bg-grey;
}

@media screen and (max-width: 1366px) {
  .landing-container {
    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }
  }

  .landing-banner {
    height: 700px;

    &-text {
      h1 {
        font-size: 48px;
      }

      h2 {
        font-size: 36px;
      }

      p.slogan {
        font-size: 24px;
      }
    }
  }

  .landing-section-two {
    padding: 60px 0;
  }

  .landing-section-four {
    &-image {
      padding: 30px 30px 50px;
      height: auto;

      .landing-section-four-list {
        margin-left: 35px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .landing-banner {
    height: 550px;

    &-text {
      margin: 8vw 0 0 9vw;
      width: 55%;
    }

    .steps {
      bottom: 15%;
    }
  }

  .landing-section-three {
    padding: 30px 0 0;
  }
}

@media screen and (max-width: 812px) {
  .landing-container {
    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 20px;
    }
  }

  .landing-banner {
    height: 350px;

    &-text {
      h1 {
        font-size: 32px;
      }

      h2 {
        font-size: 20px;
      }

      p.slogan {
        font-size: 16px;
      }
    }
  }

  .landing-section-two {
    padding: 30px 0;
  }

  .landing-section-three {
    ul {
      flex-wrap: wrap;

      li {
        width: 50%;
      }
    }
  }

  .landing-section-four {
    &-image {
      padding: 20px 50px;

      .landing-section-four-list {
        margin-left: 0;
      }
    }
  }

  .landing-section-five {
    &-overlay {
      padding: 35px 0;
    }
  }
}

@media screen and (max-width: 576px) {
  .header {
    .header-login-btn {
      padding: 0;
      margin: 0 11px;
    }

    .header-hamburger {
      display: none;
    }
  }

  .landing-container {
    h1 {
      font-size: 22px;
      margin: 10px 0;
    }

    h2 {
      font-size: 17px;
      margin: 0;
    }

    h3 {
      font-size: 14px;
    }
  }

  .landing-banner {
    height: 275px;

    .steps {
      bottom: 13%;
    }

    &-text {
      width: 65%;

      h1 {
        font-size: 24px;
      }

      h2 {
        font-size: 18px;
      }

      p.slogan {
        font-size: 13px;
      }
    }
  }

  .landing-section-three {
    ul {
      li {
        width: 100%;
        padding: 15px 5px;
      }
    }
  }

  .landing-section-four {
    &-image {
      padding: 0 20px 15px;
    }

    ul {
      li {
        padding: 15px;
        font-size: 13px;
      }
    }
  }
}


// news section
.news-section {
  padding: 2rem;
  width: 100%;

  .landing-news-btn{
  display: flex;
  justify-content: space-between;
  }
  .landing-news-btn-left, .landing-news-btn-right {
    width: 5%;
    height: auto;
    line-height: 0;
    font-size: 38px;
    align-self: center;
    cursor: pointer;
    color: #f1c400;
    border: none;
  }
  .landing-news-btn-left {
      float: left;   
    }
  .landing-news-btn-right {
      float: right;       
  }
  

  .page-title {
    
    text-align: left;
    h1{
      margin: 0 0 30px 0;
      font-size: 30px;
    }
  }

  .news-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 2rem 0;


    .ant-spin-container {
      min-height: 120px;
    }

    .carousel-content-container {
      width: 100%;
    }

  }

}

  