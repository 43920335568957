$color-main: #f1c400;
$color-bg-alert: rgba(255, 255, 255, 0.7);

.dashboard-container {
  padding: 20px;

  .dashboard-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .expiry-date-container {
      display: flex;
      align-items: center;
    }

    .expiry-date-icon {
      width: 32px;
      height: 32px;
      &.not-expired {
        color: #a8c08f;
      }
      &.expiring-soon {
        color: #eac542;
      }
      &.expired {
        color: #cb2c30;
      }
    }

    .expiry-date {
      margin-left: 8px;
    }
  }

  .widget {
    position: relative;
    height: 500px;

    .widget-alert-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $color-bg-alert;

      .widget-reload-btn {
        margin-top: 10px;
      }
    }
  }
}

.dashboard-team-action {
  padding-bottom: 20px;

  .team-action-item {
    margin-bottom: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex: 1;

    &-info {
      padding: 20px;
      color: #999;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      .name {
        margin-right: 10px;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.2;
      }

      .icon {
        font-size: 40px;
      }
    }

    &-actions {
      padding: 10px;
      display: flex;
      justify-content: space-around;
      background-color: #d9d9d9;

      a,
      button {
        color: #cb2c30;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;

        &:hover,
        &:active,
        &:focus,
        &:visited {
          color: #cb2c30;
        }
      }
    }
  }
}
