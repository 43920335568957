.login-form {
  margin-top: 10px;

  .login-form-btn {
    width: 100%;
  }

  .login-link {
    float: right;
  }

  .ant-checkbox-group-item {
    margin-bottom: 8px;
    display: flex;

    .ant-checkbox {
      top: 0;
      padding-top: 2px;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}

.login-alert {
  margin: 20px 0;
}

.login-alert-btn {
  margin-top: 16px;
  float: right;
}

.reset-password {
  width: 100%;
  max-width: 600px;
  margin: 40px auto;

  .reset-password-inner {
    padding: 40px;

    .reset-password-alert {
      margin-bottom: 20px;
    }

    .reset-password-btn {
      width: 100%;
    }
  }
}

.terms-conditions-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-modal {
    top: 0;
  }

  &-inner {
    width: 90vw;
    max-width: 140vh;
    max-height: 80vh;
    overflow-y: auto;

    h1 {
      display: table;
      color: rgba(0, 0, 0, 0.65);
      font-size: 16px;
      font-weight: 700;
    }

    ol,
    ul {
      padding-left: 24px;

      li {
        margin-bottom: 1em;
      }
    }

    ul {
      list-style-type: disc;
    }

    em {
      display: table;
      margin-bottom: 1em;
      text-align: center;
    }

    .border {
      border-bottom: 1px solid rgba(0, 0, 0, 0.65);
    }

    .center {
      margin-left: auto;
      margin-right: auto;
    }

    .break {
      height: 40px;
      clear: both;
    }
  }
}

.intro {
  .two-factor-icon {
    margin-right: 5px;
  }
  .description {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    background-color: #f4f4f4;
    padding: 5px;
    font-size: 11px;

    .exclamation-icon {
      font-size: 12px;
      margin-left: 5px;
    }

    ul {
      list-style: disc;
      padding: 10px 20px;
    }
  }
}
