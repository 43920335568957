.user-inner {
  height: 600px;
  padding: 40px;

  .user-avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .user-avatar-change {
      margin-top: 20px;
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .user-info {
    &-item {
      margin-bottom: 19px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;
    }
  }
}

@media (max-width: 1200px) {
  .user-inner {
    height: auto;
  }
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-form-item {
  margin-bottom: 5px;
}

.user-edit-modal {
  .PhoneInput {
    .PhoneInputInput {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 32px;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      line-height: 1.5;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 0;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .PhoneInputCountry {
      .PhoneInputCountryIconUnicode {
        font-size: 18px;
      }
    }
  }
}
