$color-theme: #343579;
$color-border: #d9d9d9;
$color-bg-grey: #f0f0f0;
$color-mtpd: #fabc3c;
$color-rto: #dd6031;
$color-rpo: #aabd8c;
$color-collection: (#c9e094, #fffa84, #f9c761, #dd7049, #241023);

%single-line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

%btn {
  background-color: $color-theme;
  border-color: $color-theme;
}

%select-item {
  position: relative;
  background-color: $color-bg-grey;
  border: 1px solid $color-bg-grey;
  cursor: pointer;
}

%select-item-active {
  border-color: $color-theme;
}

%select-item-active-icon {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $color-theme;
  color: #fff;
  text-align: center;
  line-height: 20px;
  font-family: FontAwesome, cursive !important;
}

.ant-affix {
  overflow: visible;
}

.bia-info-icon {
  margin-left: 10px;
  color: $color-theme;
  font-size: 14px;
  cursor: pointer;
}

.bia-container {
  padding: 40px 20px;

  .bia-btn {
    @extend %btn;

    &[disabled] {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: $color-border;
    }
  }

  .bia-nav-list {
    padding: 20px;
    max-height: 50vh;
    overflow-y: auto;

    .nav-list-category {
      @extend %single-line;
    }

    .nav-item {
      margin: 20px 10px;
      padding: 0 10px;
      line-height: 24px;
      font-weight: 700;
      background-color: $color-bg-grey;
      cursor: pointer;
      transition: all 0.2s ease-out;
      @extend %single-line;
    }

    .nav-item-unselectable {
      margin: 10px;
    }
  }

  .bia-sca-question {
    padding: 20px;

    .bia-sca-input {
      margin-top: 20px;
    }

    &-item {
      padding: 40px 20px;
      border-bottom: 1px solid $color-border;

      .bia-mtpd-select {
        width: 100%;
      }

      .ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
      .ant-radio-checked .ant-radio-inner {
        border-color: $color-theme;
      }

      .ant-radio-inner::after {
        background-color: $color-theme;
      }

      .ant-radio-button-wrapper {
        width: 100px;
        padding: 0 5px;
        text-align: center;

        &:hover,
        &.ant-radio-button-wrapper-checked {
          color: #fff;
          border-color: $color-theme;
          background-color: $color-theme;
          box-shadow: -1px 0 0 0 $color-theme;
        }
      }
    }
  }

  .bia-mtpd {
    padding: 20px;
  }

  .bia-sca-rate {
    padding: 20px;

    &-category {
      margin: 40px 0;

      .category-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        border-bottom: 1px solid $color-border;
      }

      .category-list {
        margin: 20px 0;

        .category-item {
          padding: 20px 10px;
          text-align: center;
          @extend %select-item;

          @for $i from 1 through length($color-collection) {
            &:nth-child(#{$i}) {
              .rating {
                background-color: nth($color-collection, $i);
              }
            }
          }

          &.active {
            @extend %select-item-active;

            &:before {
              content: '\f00c';
              @extend %select-item-active-icon;
            }
          }

          .title {
            text-transform: uppercase;
            font-weight: 700;
          }

          .rating {
            width: 40px;
            height: 40px;
            margin: 14px auto;
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            line-height: 40px;
            border-radius: 50%;
            box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.4);
          }

          .description {
            font-size: 10px;
          }
        }
      }
    }
  }

  .tactical-bia-inner {
    padding: 20px;

    .table-header-cell-mtpd {
      color: $color-mtpd;
    }

    .table-header-cell-rto {
      color: $color-rto;
    }

    .table-header-cell-rpo {
      color: $color-rpo;
    }

    .rto-slider {
      .ant-slider-track {
        background-color: $color-rto !important;
      }

      .ant-slider-handle {
        border-color: $color-rto !important;
        background-color: $color-rto;
      }
    }

    .rpo-slider {
      .ant-slider-track {
        background-color: $color-rpo !important;
      }

      .ant-slider-handle {
        border-color: $color-rpo !important;
        background-color: $color-rpo;
      }
    }

    .table-cell-divider {
      padding: 0;
      background-color: rgba(0, 0, 0, 0.65);
    }
  }
}
