$color-white: #fff;
$color-bg: #2d2929;

.footer {
  width: 100%;
  padding: 10px 20px;
  background-color: $color-bg;
  color: $color-white;
  font-size: 14px;

  &-copyright {
    font-weight: 300;
    text-align: center;
    padding: 5px;
    opacity: 0.8;
    font-size: 12px;

    > .divider {
      margin: 12px 0;
      opacity: 0.1;
    }

    > strong {
      font-weight: 600;
    }
  }

  &-social-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > li {
      &:not(:last-child) {
        margin-right: 10px;
      }

      > button {
        color: $color-bg;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .footer {
    &-social-links {
      margin-top: 10px;
      justify-content: center;
    }
  }
}
